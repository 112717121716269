var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publication-table rounded-lg overflow-hidden border border-gray-100"},[_c('JdsDataTable',{attrs:{"headers":_vm.tableHeader,"items":_vm.items,"loading":_vm.loading,"pagination":_vm.pagination,"empty-text":"Data tidak tersedia"},on:{"next-page":function($event){return _vm.onPaginationChange('next-page', $event)},"previous-page":function($event){return _vm.onPaginationChange('previous-page', $event)},"per-page-change":function($event){return _vm.onPaginationChange('per-page-change', $event)},"page-change":function($event){return _vm.onPaginationChange('page-change', $event)},"change:sort":function($event){return _vm.onSortChange($event)}},scopedSlots:_vm._u([{key:"item.title",fn:function({item}){return [_c('p',{staticClass:"line-clamp-1"},[_vm._v(" "+_vm._s(item.service_name || '-')+" ")])]}},{key:"item.author",fn:function({item}){return [_c('p',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(item.opd_name || '-')+" ")])]}},{key:"item.user",fn:function({item}){return [_c('p',[_vm._v(" "+_vm._s(_vm.getGovermentAffair(item.service_user) || '-')+" ")])]}},{key:"item.technical",fn:function({item}){return [_c('p',{class:{
          'font-lato font-bold text-[14px] leading-[23px] capitalize' : true,
          'w-fit rounded-[6px] bg-[#E3F0FF] text-blue-800 px-2 py-[2px]': item.technical === 'ONLINE',
          'w-fit rounded-[6px] bg-gray-200 text-gray-600 px-2 py-[2px]': item.technical === 'OFFLINE'
        }},[_c('span',{class:{
            'w-2 h-2 rounded-full inline-block mr-[5px]': true,
            'bg-blue-800': item.technical === 'ONLINE',
            'bg-gray-600': item.technical === 'OFFLINE',
          }}),_vm._v(" "+_vm._s(item.technical.toLowerCase() || '-')+" ")])]}},{key:"item.updated",fn:function({item}){return [_c('p',[_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at) || '-')+" ")])]}},{key:"item.status",fn:function({item}){return [_c('p',[_vm._v(" "+_vm._s(_vm.getServiceStatus(item.status) || '-')+" ")])]}},{key:"item.action",fn:function({item}){return [_c('PublicationTableAction',{attrs:{"item":item},on:{"delete":function($event){return _vm.$emit('delete', $event)}}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }